import React, { useContext  }  from "react"
import { Link } from "gatsby"
import VDPLeasePopup from "./VDPLeasePopup"
import AutoCheckButton from "../autocheckButton/AutoCheckButton"
import { FinanceCalculatorContext } from "../modal/financing/storage/CalculatorContext"
import FinanceCalculator from "../../Modules/financeCalculator"
import useIncentives from "./hooks/VDPIncentives"
import useOptionalIncentives from "./hooks/VDPOptionalIncentives"
import { captureEvent } from 'event-service/modules/capture'
import sticker from './img/window-sticker.png'

import { configurableString, configurableLink } from '../../Modules/configurableVehicleInfo'
import InnerHTML from 'dangerously-set-html-content'

import style from "./vdp.module.scss"
export default function VDPFinancing(props) {
  const { Pricing, ListOfAppliedOffers, ListOfAvailableOffers } = props.vehicle
  const {IsNew} = props.vehicle.VehicleInfo
  const { netPriceCustomText } = props
  const { months, downpayment, rates } = props.financing
  const { buttons, fallbackText } = props
  const { PosDifText, NegDifText } = props
  const { retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText } = props
  const { asLowAs, unavailableImg } = props
  const { strikeMSRP, AutoCheckEnabled } = props
  const topRate = rates[0]
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)
  const [renderIncentives, discountSum] = useIncentives(ListOfAppliedOffers,Pricing)
  const [renderOptionalIncentives, optionalDiscountSum] = useOptionalIncentives(ListOfAvailableOffers,Pricing)
  const [,incentivesSum]  = useIncentives(ListOfAppliedOffers,Pricing)
  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, discountSum, calc.rebate, months, topRate, downpayment
  )

  const { replacePrice, newPriceText } = props

  const renderMSRPRetailLabel = (isNew,Pricing) => {
    return isNew ? "MSRP" : Pricing.List > Pricing.ExtraPrice1 ? retailPriceDiscountCustomText: retailPriceCustomText
  }


  const [, setFinanceModalContext] = useContext(FinanceCalculatorContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price - discountSum, vehicleInfo: props.vehicle.VehicleInfo })
  }

  const configurablePriceInfo = price - incentivesSum;
  
  const dataLayer = (...args) => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(...args);
    }
  };
 
  const setStyle = (button) => {
    if (button.hasOwnProperty("overrideTheme") && button.overrideTheme == true) {
      const styles = [
        button.hasOwnProperty("btncolor") && ["backgroundColor", button.btncolor],
        button.hasOwnProperty("btntxtcolor") && ["color", button.btntxtcolor]
      ].filter(el => el != false)
      return Object.fromEntries(styles)
    }
  }
  const showPhoto = () => {
    let mainPhoto = {}
    if (props.vehicle.MainPhotoUrl === "") {
      mainPhoto = unavailableImg
    } else {
      mainPhoto =  props.vehicle.MainPhotoUrl;
    }
    return mainPhoto
  }
  const renderHTMLBtn = (html) => (
    <div className="vdp-html-btns" data-vehicle-info={JSON.stringify({ ...props.vehicle.VehicleInfo, price: props.vehicle.Pricing.ExtraPrice1})} onClick={() => {
        dataLayer({
              'event': 'CTA_event',
              'VIN': props.vehicle.VehicleInfo.VIN,
              'MSRP': props.vehicle.Pricing.List,
              'reduced_price': props.vehicle.Pricing.ExtraPrice1
            }
        )}}>
      <InnerHTML html={configurableString(html, props.vehicle.VehicleInfo, showPhoto(), configurablePriceInfo)} />
    </div>
  )
   
  return (
      <>
        <div id="details_plus_wrapper" className={`${style["normal-vdpf"]} dealer-${props.vehicle.VehicleInfo.DealerID}`}>
          <section id="details_plus" className={style["details"]}>
            <div id="details_plus_inner" className={style["details__inner"]}>
              { ListOfAvailableOffers?.length > 0 &&  renderOptionalIncentives()}
            <div className={`${style.controls} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-controls' : 'used-vdp-controls'}`}>
              {buttons.map(button => (
                  (button.html != undefined && button.html.split(' ').join('') === "{{carfax}}")?
                  (props.vehicle.VehicleInfo.IsNew === false)?       
                      (props.vehicle.VehicleInfo.VehicleHistoryReportLink) ?
                  
                        <a style={{textAlign: "center", marginLeft:'4px', color: '#ffffff', alignItems: 'center', display: 'flex', cursor: 'pointer', justifyContent: 'center', marginBottom: '24px', marginTop: '20px', width: "100%", minHeight: '48px'}} href={props.vehicle.VehicleInfo.VehicleHistoryReportLink} target="_blank">
                        <img style={{ maxHeight: '150px', maxWidth: '225px'}} src={props.vehicle.VehicleInfo.Category5} />
                      </a>
          
                      :
                 
                      <a style={{textAlign: "center", marginLeft:'4px', color: '#ffffff', alignItems: 'center', display: 'flex', cursor: 'pointer', justifyContent: 'center', marginBottom: '24px', marginTop: '20px', width: "100%", minHeight: '48px'}} href={`http://www.carfax.com/cfm/ccc_DisplayHistoryRpt.cfm?vin=${props.vehicle.VehicleInfo.VIN}`}  target="_blank">
                        <img style={{ maxHeight: '150px', maxWidth: '225px'}}  src="https://media.dealermasters.com/Carfax/carfax-general.webp" />
                      </a> 
                    :
                    <></>
                
              
                :  
                (button.html != undefined && button.html != '' && button.html != '<p></p>')
                  ? (button.html === "{{autocheck}}") ? 
                  <AutoCheckButton 
                    vin={props.vehicle.VehicleInfo.VIN} 
                    IsNew={props.vehicle.VehicleInfo.IsNew} 
                    AutoCheckEnabled={AutoCheckEnabled} 
                  /> : renderHTMLBtn(button.html)
                  :
                             
                  (button.buttonLink.includes('tel:')
                    ?
                    <a className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} href={button.buttonLink}>
                      <button className={style["control"]} style={setStyle(button)}
                        onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          dataLayer({
                            'event': 'CTA_event',
                            'VIN': props.vehicle.VehicleInfo.VIN,
                            'MSRP': Pricing.List,
                            'reduced_price': Pricing.ExtraPrice1
                          });
                          contextualizeItem();
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : button.buttonText
                        }
                      </button>
                    </a>
                    :
                    <Link className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} to={configurableString(button.buttonLink, props.vehicle.VehicleInfo)}>
                      <button className={style["control"]} style={setStyle(button)}
                        onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          dataLayer({
                            'event': 'CTA_event',
                            'VIN': props.vehicle.VehicleInfo.VIN,
                            'MSRP': Pricing.List,
                            'reduced_price': Pricing.ExtraPrice1
                          });
                          contextualizeItem();
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : configurableString(button.buttonText, props.vehicle.VehicleInfo)
                        }
                      </button>
                    </Link>                       
                  )
              ))}
              <a className={style["window-sticker"]} href={`https://www.ipacket.info/${props.vehicle.VehicleInfo.VIN}`}  target="_blank">
                <img src={sticker} style={{width: '140px', height: 'auto'}} />
              </a> 
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

VDPFinancing.defaultProps = {
  financing: {
    months: 0, downpayment: 0
  }
}
