function FinanceCalculator(msrp) {
  this.msrp = msrp
  this.discount = 0
  this.rebate = 0

  this.monthlyPayment = 0

  const formatPrice = (price) => {
    return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  this.applyDiscount = (discount) => {
    this.discount = this.msrp - discount
    return formatPrice(this.discount)
  }

  this.applyRebate = (rebate) => {
    this.rebate = this.msrp - this.discount - rebate
    return formatPrice(this.rebate)
  }

  this.getSavings = () => {
    return formatPrice(this.discount + this.rebate)
  }

  this.calculateMonthlyPayment = (price, discount, rebate, _months, _rate, _downpayment) => {
    // Look up the input and output elements in the document
    var amount = (price - discount - rebate)
    var finalAmount = amount - (amount * (_downpayment / 100))

    //console.log("amount " + amount)
    //console.log("finalAmount " + finalAmount)

    // Get the user's input from the input elements. Assume it is all valid.
    // Convert interest from a percentage to a decimal, and convert from
    // an annual rate to a monthly rate. Convert payment period in years
    // to the number of monthly payments.

    var principal = parseFloat(finalAmount);
    var interest = parseFloat(_rate) / 100 / 12;
    var payments = _months;

    //console.log("principal " + principal)
    //console.log("interest " + interest)
    //console.log("payments " + payments)

    // Now compute the monthly payment figure.
    var x = Math.pow(1 + interest, payments); // Math.pow() computes powers
    var monthly = (principal * x * interest) / (x - 1);

    var y = Math.pow(1 + interest, (payments / 12) * 52);
    var weekly = (principal * y * interest) / (y - 1);
    // If the result is a finite number, the user's input was good and
    // we have meaningful results to display
    if (isFinite(monthly)) {
      const payment = monthly
      const total = (monthly * payments).toFixed(2);
      const totalinterest = ((monthly * payments) - principal).toFixed(2);

      this.monthlyPayment = payment
      return this.monthlyPayment.toFixed(0)
    }
    else {
      return ""
    }
  }
}

FinanceCalculator.formatPrice = (price, fixed) => {
  return price.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

FinanceCalculator.formatLocale = (price, fixed) => {
  const _price = parseFloat(price || 0)
  const _fixed = parseInt(fixed || 0)
  const formatted = FinanceCalculator.formatPrice(_price, _fixed)
  return formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default FinanceCalculator