import React, { useContext  }  from "react"
import { Link } from "gatsby"
import VDPLeasePopup from "./VDPLeasePopup"
import style from "./vdp.module.scss"
import useIncentives from "./hooks/VDPIncentives"
import { FinanceCalculatorContext } from "../modal/financing/storage/CalculatorContext"
import FinanceCalculator from "../../Modules/financeCalculator"

export default function VDPBanner(props) {
  const { Year, Make, Model, Trim } = props.vehicle.VehicleInfo
  const { ListOfPhotos } = props.vehicle
  const { asLowAs } = props
  const { IsNew } = props.vehicle.VehicleInfo
  const { netPriceCustomText } = props
  const { months, downpayment, rates } = props.financing
  const { fallbackText } = props
  const { PosDifText, NegDifText } = props
  const { Pricing, ListOfAppliedOffers } = props.vehicle
  const { strikeMSRP } = props
  const topRate = rates[0]
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)
  const [renderIncentives, discountSum] = useIncentives(ListOfAppliedOffers,Pricing)
  const { replacePrice, newPriceText } = props
  const { retailPriceCustomText, retailPriceDiscountCustomText } = props
  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, discountSum, calc.rebate, months, topRate, downpayment
  )
  const unavailableBanner = "https://media.dealermasters.com/AstonMartin/Newport/vdp-coming-soon-banner.webp"

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableBanner
  }

  const renderMSRPRetailLabel = (isNew,Pricing) => {
    return isNew ? "MSRP" : Pricing.List > Pricing.ExtraPrice1 ? retailPriceDiscountCustomText: retailPriceCustomText
  }

  const [, setFinanceModalContext] = useContext(FinanceCalculatorContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price - discountSum, vehicleInfo: props.vehicle.VehicleInfo })
  }

  return (
    <>
        <div className={style["banner__wrap"]}>
            {(ListOfPhotos.length === 0)
                ?
                <img className={style["banner-image"]}
                    alt=""
                    src={unavailableBanner} />
                :
                <img className={style["banner-image"]}
                    alt={`${Make} ${Year} ${Model} ${Trim}`}
                    src={ListOfPhotos[ListOfPhotos.length - 1].PhotoUrl}
                    onError={(e) => handleImgError(e)} />
            }
              {/* <ul className={style["offers"]}>
                { replacePrice ? 
                    <li className={style["offer"]}>
                      <p className={style["offer-name"]}>
                        { newPriceText !== undefined ? newPriceText : '' }
                      </p>
                    </li>
                  :
                  (Pricing.ExtraPrice1 > Pricing.List && Pricing.ExtraPrice1 > 0  && Pricing.List > 0 && !IsNew)
                      ?
                      <span></span>
                      :
                  (Pricing.ExtraPrice1 < Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0 && (Pricing.Special == 0 ||Pricing.Special == Pricing.List || Pricing.Special == Pricing.ExtraPrice1)) ? <li className={style["offer"]}>
                        <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                          <p className={style["offer-value"] + " " + ((IsNew && strikeMSRP) ? style["strike-through"]: "")}>
                            <span className={style["offer-price-name"]}>{renderMSRPRetailLabel(IsNew,Pricing)}</span>
                            {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                          </p>
                        </div>
                        {Pricing.ExtraPrice1 > 0 &&
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ NegDifText}</button></p>
                          <p className={style["offer-value"]}>
                            {Pricing.ExtraPrice1 > 0 ? `-$${FinanceCalculator.formatLocale(Pricing.List - Pricing.ExtraPrice1)}` : fallbackText}
                          </p>
                        </div>
                        }
                      </li> :
                      ((Pricing.ExtraPrice1 > Pricing.List && Pricing.List > 0) || (Pricing.Special < Pricing.List && Pricing.List != Pricing.Special && Pricing.Special > 0)) ? <li className={style["offer"]}>
                            <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                              <p className={style["offer-value"] + " " + ((IsNew && strikeMSRP) ? style["strike-through"]: "")}>
                                <span className={style["offer-price-name"]}>{renderMSRPRetailLabel(IsNew,Pricing)}</span>
                                {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                              </p>
                            </div>

                            {(Pricing.Special > 0 && Pricing.Special < Pricing.ExtraPrice1 && Pricing.Special != Pricing.List) ?
                              <>
                                <div className={style["offer-cont"]}>
                                  <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ NegDifText }</button></p>
                                  <p className={style["offer-value"]}>
                                    -${FinanceCalculator.formatLocale(Pricing.List - Pricing.Special)}
                                  </p>
                                </div>
                                  <div className={style["offer-cont"]}>
                                    <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ PosDifText }</button></p>
                                    <p className={style["offer-value"]}>
                                      {Pricing.ExtraPrice1 > 0 ? `+$${FinanceCalculator.formatLocale(Pricing.ExtraPrice1 - Pricing.Special)}` : fallbackText}
                                    </p>
                                  </div>
                                </>
                                :

                              <div className={style["offer-cont"]}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ PosDifText }</button></p>
                              <p className={style["offer-value"]}>
                            {Pricing.ExtraPrice1 > 0 ? `+$${FinanceCalculator.formatLocale(Pricing.ExtraPrice1 - Pricing.List)}` : fallbackText}
                              </p>
                              </div>
                            }
                          </li> :
                      (Pricing.ExtraPrice1 == Pricing.List && price != 0  && discountSum > 0)
                          ?
                          <li className={style["offer"]}>
                            <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                              <p className={style["offer-value"]}>
                                <span className={style["offer-price-name"]}>MSRP</span>
                                {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                              </p>
                            </div>
                          </li>:
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>{(Pricing.ExtraPrice1 == Pricing.List && price != 0) && IsNew ? "MSRP" : "Dealer Price"}</button></p>
                          <p className={style["offer-value"]}>
                            {price > 0 ? `$${FinanceCalculator.formatLocale(price)}` : fallbackText}
                          </p>
                        </div>
                      </li>
                }
                { ListOfAppliedOffers.length > 0 && renderIncentives() }
                {((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0) && (discountSum == 0) && !((Pricing.ExtraPrice1 > Pricing.List) && !IsNew)) &&
                   <hr style={{ width: "100%" }} />
                }
           
                  { (((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0) || (discountSum > 0)) ) &&
                    <div className={style["offer-cont"]}>
                      {netPriceCustomText? <b style={{ fontSize: "1.25em" }}>{ netPriceCustomText }</b> : <b style={{ fontSize: "1.25em" }}>Net price</b>}
                      <p style={{ fontSize: "1.25em" }}>
                        {`$${FinanceCalculator.formatLocale(price - discountSum)}`}
                      </p>
                    </div>
                  }


                {
                  (((Pricing.ExtraPrice1 - Pricing.List) < 0) && ((discountSum > 0 || (Pricing.List - Pricing.ExtraPrice1) > 0 ) && Pricing.ExtraPrice1 != 0)) && (
                      <>
                        <div className={style["offer-cont-savings"]}>
                          <b>Total savings</b>
                          <p>{`$${FinanceCalculator.formatLocale(discountSum + (Pricing.List - Pricing.ExtraPrice1))}`}</p>
                        </div>
                        <hr style={{ width: "100%" }} />
                      </>
                  )
                }
                {
                  (price > 0 && asLowAs) && (
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}>
                            <button className={style["offer-btn"]}></button>
                          </p>
                            <Link className={style["offer-value"]} to={"#financecal"}>
                            <button className={style["offer-btn offer-btn_underline"] + " " + style["offer-finance"]} onClick={contextualizeItem}>
                              {`As Low As $${FinanceCalculator.formatLocale(monthly)}/Mo`}
                            </button>
                            </Link>
                        </div>
                      </li>
                  )
              }
              {IsNew && <VDPLeasePopup vehicleInfo={props.vehicle.VehicleInfo} />}
            </ul> */}
        </div>

    </>
  )
}
