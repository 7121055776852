import React, { useState } from "react"

import style from "./vdp.module.scss"

import iconArrow from "./img/icon-arrow-down-v4.svg"

export default function VDPOptions({ vehicle }) {
  const { ListOfOptions } = vehicle
  const [currentTab, setTab] = useState(-1)

  const toggleTab = (idx) => {
    if (currentTab == idx) {
      setTab(-1)
    } else {
      setTab(idx)
    }
  }

  const renderItems = (key) => (
    <ul className={style["spec-sublist"]}>
      {ListOfOptions[key].map((option, jdx) => (
        <li key={jdx} className={style["spec-sublist__item"]}>
          <span className={style["spec-sublist__item-value"]}>
            {option.Description}
          </span>
        </li>
      ))}
    </ul>
  )

  return (
      Object.keys(ListOfOptions).length > 0 &&
    <section className={style["detailed-info"]}>
      <h2 className={style["detailed-info__header"]}>
        Detailed Specifications
      </h2>

      <ul className={style["detailed-info__list"]}>
        {Object.keys(ListOfOptions).map((key, idx) => (
          <li key={key} className={style["detailed-info__item"]} >
            <div style={{display: "flex"}}>
              <h3 className={style["detailed-info__item-header"]}
                onClick={() => toggleTab(idx)}>
                {key}
              </h3>
              <img className={style[currentTab == idx && "caret-active"]}
                src={iconArrow} alt="" onClick={() => toggleTab(idx)}/>
            </div>
            { currentTab == idx && renderItems(key)}
          </li>
        ))
        }
      </ul>
    </section>
  )
}