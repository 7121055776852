import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import FullSlider from "../components/fullSlider/FullSlider";

export default function useFullSlider(imageList, slide, imageFallback) {
  const [visible, toggle] = useState(false)

  const renderSlider = () => (
    visible &&
    <FullSlider closeSlider={closeSlider}
      imageList={imageList}
      index={slide}
      unavailableImg={imageFallback} />
  )

  const openSlider = () => {
    toggle(true)
  }

  const closeSlider = () => {
    toggle(false)
  }

  return [renderSlider, openSlider, closeSlider]
}

