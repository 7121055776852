import React, { useState } from "react"
import PrimarySlider from "./slider/PrimarySlider";
import ThumbnailSlider from "./slider/ThumbnailSlider"
import useFullSlider from "../../hooks/useFullSlider";

import style from "./vdp.module.scss"

export default function VDPImageSlider(props) {
  const { ListOfPhotos } = props.vehicle
  const { unavailableImg } = props
  
  const isnew = props.vehicle.VehicleInfo.IsNew
  const year = props.vehicle.VehicleInfo.Year
  const make = props.vehicle.VehicleInfo.Make
  const model = props.vehicle.VehicleInfo.Model
  const extraprice1 = props.vehicle.Pricing.ExtraPrice1.toLocaleString()
  const listprice = props.vehicle.Pricing.List.toLocaleString()
  const vin = props.vehicle.VIN
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const [
    renderFullSlider, openFullSlider
  ] = useFullSlider(ListOfPhotos, currentSlide, unavailableImg)

  const filteredPhotos = ListOfPhotos.slice(0, -1);

  return (
    <>
      <section className={style["slider"]}>
        <div className={style["slideTopCont"]}>
          <div className={style["slideTop"]}>
            <div className={style["ymm"]}>
              <h2>
                <span className="amnb147Condition">{isnew ? 'New' : 'Pre-Owned'} </span>
                <span className="amnb147Year">{year && year} </span>
                <span className={`${style["thicc"]} amnb147Make`}>{make && make} </span> 
                <span className={`${style["thicc"]} amnb147Model`}>{model && model}</span>
              </h2>
            </div>
            <div className={style["priceVin"]}>
              <h3 className="amnb147Price">
                { extraprice1 >= listprice ? 'MSRP: ' +'$' + listprice : 'Dealer Price: ' +'$' + extraprice1 }
              </h3>
              <p className="amnb147Vin">
                {vin && 'VIN #:' + vin}
              </p>
            </div>
          </div>
        </div>
        <div style={{ cursor: "pointer" }} onClick={openFullSlider}>
          <PrimarySlider slide={currentSlide} setSlide={setCurrentSlide}
            ListOfPhotos={filteredPhotos} unavailableImg={unavailableImg} />
        </div>
        <ThumbnailSlider slide={currentSlide} setSlide={setCurrentSlide}
          ListOfPhotos={filteredPhotos} unavailableImg={unavailableImg} />
      </section>
      { renderFullSlider() }
    </>
  )
}
